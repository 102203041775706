import "./App.css";
import Footer from "../Footer";
import Header from "../Header";
import DocumentPreview from "../DocumentPreview";

function App() {
    return (
        <div className="App">
            <Header />
            <section className="Menu">
                <DocumentPreview
                    selectedDocument="4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_Ecool.pdf"
                />
                <Footer />
            </section>
        </div>
    );
}

export default App;
