import React from "react";
import "./Header.css";

function Header() {
    return (
        <section className="Header">
            User Manuals
        </section>
    );
}

export default Header;
