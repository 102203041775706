import React from "react";
import "./DocumentPreview.css";

function DocumentPreview({ selectedDocument }) {
    const url = `https://addvolt-resources.s3.eu-central-1.amazonaws.com/support/${selectedDocument}`;
    const isMobile = window.innerWidth < 768;

    if (isMobile) {
        const inBrowserReaderUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`;
        return (
            <div className="DocumentPreview">
                <iframe
                    src={inBrowserReaderUrl}
                    title="Document Preview"
                    width="100%"
                    height="100%"
                    style={{
                        height: 'calc(90vh - 80px)'
                    }}
                    frameBorder="0"
                    scrolling="no"
                />
            </div>
        );
    }

    return (
        <div className="DocumentPreview">
            <object
                data={url}
                type="application/pdf"
                width={window.innerWidth * 0.75}
                height="750px"
            >
                <p>
                    PDF can not be shown on your browser.
                    <br />
                    Please download the PDF to view it:&nbsp;
                    <a href={url}>Download PDF</a>.
                </p>
            </object>
        </div>
    );
}

export default DocumentPreview;
